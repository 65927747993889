/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:a265eda1-2b93-4171-bf53-0e1bc05ddd23",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_YLdiNSU9y",
    "aws_user_pools_web_client_id": "1j7jk5tok6i24ck469mp2rro53",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_appsync_graphqlEndpoint": "https://t73keypelrewzkds43atcpauqe.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-mfiwoaw2jzbkxhvznxupdjn4fe",
    "aws_cloud_logic_custom": [
        {
            "name": "predictservice",
            "endpoint": "https://q92qdkxube.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "predictguestservice",
            "endpoint": "https://oyl9rhw5q4.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "serviceitem110028-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
